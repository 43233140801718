import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { useEffect } from "react";
import { Constant } from "../constants/Constant";
import { NetworkConstant } from "../network/NetworkConstant";
import { useDispatch, useSelector } from "react-redux";
import { makeNetworkCallGetAllUser } from "../redux/action/EnrollmentAction";
import { ActionType } from "../redux/action/actionType";
import { BlueLoader, inputPropsStyle } from "../util/AppUtils";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import { TextField } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  formatNumber,
  parseDataIntoDateAndTimeInHumanReadableForm,
  parseDate,
} from "../util/CommonUtils";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./Enrollment.css";
import { makeNetworkCallGetAllPublishedCourses } from "../redux/action/action";
import { RequestType } from "../network/RequestType";
import { styled } from "@mui/material/styles";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Lottie from "react-lottie-player";
import paymentAnimation from "../assets/lottie/payment_animation.json";

const Enrollment = () => {
  const dispatch = useDispatch();
  const enrollmentReducer = useSelector((state) => state.enrollmentReducer);
  const apiState = useSelector((state) => state.apiStateReducer);
  const courseReducer = useSelector((state) => state.courseReducer);
  const courseStatus = [
    Constant.COURSE_STATUS_ALL,
    Constant.COURSE_STATUS_ACTIVE,
    Constant.COURSE_STATUS_EXPIRED,
  ];
  const [courseStatusValue, setCourseStatusValue] = useState(
    Constant.COURSE_STATUS_ALL
  );
  const [courseValue, setCourseValue] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [enrolledUserList, setEnrolledUserList] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [totalPayment, setTotalPayment] = useState(0);

  const handlePopper = (newPlacement) => (event) => {
    calculateTotalPayment();
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  useEffect(() => {
    fetchPublishedCourse();
    fetchGetAllUser(Constant.COURSE_STATUS_ALL, "");
    return () => {
      setEnrolledUserList([]);
      dispatch({
        type: ActionType.RESET_GET_ALL_USER,
        payload: {},
      });
      dispatch({
        type: ActionType.RESET_GET_ALL_PUBLISHED_COURSES,
        payload: {},
      });
    };
  }, []);

  //? handle select date
  const handleSelect = (date) => {
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    setEnrolledUserList([]);
    const choseStartDate = new Date(parseDate(date.selection.startDate));
    const choseEndDate = new Date(parseDate(date.selection.endDate));

    let filteredData = enrollmentReducer?.getAllUser?.responseData?.filter(
      (val) => {
        return (
          new Date(parseDate(val?.courseResponse[0]?.coursePurchasedDate)) >=
            choseStartDate &&
          new Date(parseDate(val?.courseResponse[0]?.coursePurchasedDate)) <=
            choseEndDate
        );
      }
    );
    setEnrolledUserList(filteredData);
  };

  //? calculate total payment
  const calculateTotalPayment = () => {
    setTotalPayment(0);
    var totalCalculatedPayment = 0;
    for (var i = 0; i < enrolledUserList?.length; i++) {
      for (var j = 0; j < enrolledUserList[i]?.courseResponse?.length; j++) {
        if (
          enrolledUserList[i]?.courseResponse[j]?.hasOwnProperty(
            "transactionAmount"
          )
        ) {
          totalCalculatedPayment =
            totalCalculatedPayment +
            enrolledUserList[i]?.courseResponse[j]?.transactionAmount;
        }
      }
    }
    setTotalPayment(totalCalculatedPayment);
  };

  //? handle selection range
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  //? search by email query
  const handleSearchByEmail = (event) => {
    setSearchEmail(event.target.value);
    if (event.target.value !== "") {
      setEnrolledUserList([]);
      let filteredData = enrollmentReducer?.getAllUser?.responseData?.filter(
        (val) => {
          return val?.email?.includes(event.target.value);
        }
      );
      setEnrolledUserList(filteredData);
    } else {
      setEnrolledUserList([]);
      setEnrolledUserList(enrollmentReducer?.getAllUser?.responseData);
    }
  };

  //? handle change course status
  const handleChangeCourse = (event) => {
    setCourseStatusValue(event.target.value);
    setEnrolledUserList([]);
    setSearchEmail("");
    dispatch({
      type: ActionType.RESET_GET_ALL_USER,
      payload: {},
    });
    if (courseValue !== "") {
      fetchGetAllUser(event.target.value, courseValue);
    }
  };

  //? handle change course value
  const handleChangeCourseValue = (event) => {
    setCourseValue(event.target.value);
    setEnrolledUserList([]);
    setSearchEmail("");
    dispatch({
      type: ActionType.RESET_GET_ALL_USER,
      payload: {},
    });
    fetchGetAllUser(courseStatusValue, event.target.value);
  };

  //? handle clear data
  const handleClearData = () => {
    setShowCalendar(false);
    setSearchEmail("");
    setEnrolledUserList([]);
    setEnrolledUserList(enrollmentReducer?.getAllUser?.responseData);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //? fetch published course
  const fetchPublishedCourse = async () => {
    dispatch(
      makeNetworkCallGetAllPublishedCourses(
        RequestType.GET,
        NetworkConstant.getAllCourse,
        {},
        {}
      )
    );
  };

  //? fetch get all  user
  const fetchGetAllUser = async (courseStatus, courseId) => {
    const updatedUrl =
      courseId === ""
        ? `${NetworkConstant.getAllUser}?course-status=${courseStatus}&size=1000&page=0`
        : `${NetworkConstant.getAllUser}?course-status=${courseStatus}&courseId=${courseId}&size=1000&page=0`;
    dispatch(makeNetworkCallGetAllUser(updatedUrl, {}, {}));
  };

  useEffect(() => {
    if (enrollmentReducer?.getAllUser?.statusCode === 200) {
      setEnrolledUserList(enrollmentReducer?.getAllUser?.responseData);
    }
  }, [enrollmentReducer?.getAllUser?.statusCode]);

  useEffect(() => {
    if (courseReducer?.getAllPublishedCourses?.statusCode === 200) {
      console.log("successful");
    }
  }, [courseReducer?.getAllPublishedCourses?.statusCode]);

  //? styled row data and column

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function Row(props) {
    const { row, count } = props;
    const [open, setOpen] = useState(false);

    return (
      <>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="right">
            {<span>{count}</span>}
          </StyledTableCell>
          <StyledTableCell align="right">
            {<span>{row?.userName}</span>}
          </StyledTableCell>
          <StyledTableCell align="right">
            {<span>{row?.email}</span>}
          </StyledTableCell>
          <StyledTableCell align="right">
            {<span>{row?.contactNo}</span>}
          </StyledTableCell>
          <StyledTableCell align="right">
            {<span>{row?.obgPG}</span>}
          </StyledTableCell>
          <StyledTableCell align="right">
            {<span>{row?.obgResident}</span>}
          </StyledTableCell>
          <StyledTableCell align="right">
            {<span>{row?.interest}</span>}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  <span>Courses</span>
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>{<span>Title</span>}</StyledTableCell>
                      <StyledTableCell>
                        {<span>Start Date</span>}
                      </StyledTableCell>
                      <StyledTableCell>
                        {<span>Expiry Date</span>}
                      </StyledTableCell>
                      <StyledTableCell>{<span>Type</span>}</StyledTableCell>
                      <StyledTableCell>
                        {<span>Tran. Date</span>}
                      </StyledTableCell>
                      <StyledTableCell>{<span>Amount</span>}</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {row?.courseResponse?.map((courseData, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {<span> {courseData?.title}</span>}
                        </StyledTableCell>
                        <StyledTableCell>
                          {
                            <span>
                              {parseDate(courseData?.coursePurchasedDate)}
                            </span>
                          }
                        </StyledTableCell>
                        <StyledTableCell>
                          {<span> {parseDate(courseData?.expiryDate)}</span>}
                        </StyledTableCell>
                        <StyledTableCell>
                          {courseData?.hasOwnProperty("transactionType") ? (
                            <span> {courseData?.transactionType}</span>
                          ) : (
                            "-"
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {courseData?.hasOwnProperty("transactionDate") ? (
                            <span>
                              {parseDataIntoDateAndTimeInHumanReadableForm(
                                courseData?.transactionDate
                              )}
                            </span>
                          ) : (
                            "-"
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {courseData?.hasOwnProperty("transactionAmount") ? (
                            <span>
                              {formatNumber(courseData?.transactionAmount)}
                            </span>
                          ) : (
                            "-"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </>
    );
  }

  return (
    <>
      <div className="user-table-view">
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <div className="total-payment-popup-container">
                  <Lottie
                    loop
                    animationData={paymentAnimation}
                    play
                    style={{ width: 300, height: 200, alignSelf: "center" }}
                  />
                  <div
                    style={{
                      height: "2px",
                      backgroundColor: "#845ADF",
                      marginTop: "10px",
                      marginBottom: "20px",
                    }}
                  ></div>
                  <h3
                    style={{
                      textAlign: "center",
                      fontSize: "25px",
                    }}
                  >
                    Total payment : Rs {formatNumber(totalPayment)}
                  </h3>
                </div>
              </Paper>
            </Fade>
          )}
        </Popper>
        <div className="calendar-container">
          <Tooltip arrow>
            <Fab
              className="button-focus"
              style={{
                marginRight: "20px",
              }}
              aria-label="Calendar"
              size="small"
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopper("top")}
              onMouseLeave={handlePopper("top")}
            >
              <CurrencyRupeeIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Calendar" arrow>
            <Fab
              className="button-focus"
              style={{
                marginRight: "20px",
              }}
              aria-label="Calendar"
              size="small"
              onClick={() => {
                setShowCalendar(!showCalendar);
              }}
            >
              <CalendarMonthIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Clear" arrow>
            <Fab
              className="button-focus"
              style={{
                marginRight: "20px",
              }}
              aria-label="Clear"
              size="small"
              onClick={() => {
                handleClearData();
              }}
            >
              <ClearAllIcon />
            </Fab>
          </Tooltip>
        </div>
        {showCalendar && (
          <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
        )}
        <TextField
          style={{
            width: "100%",
            alignSelf: "center",
            marginBottom: 20,
          }}
          id="outlined-error-helper-text"
          label={<span>Search by email</span>}
          onChange={handleSearchByEmail}
          inputProps={inputPropsStyle.inputProp}
          value={searchEmail}
        />
        <FormControl
          style={{
            width: "100%",
            alignSelf: "center",
          }}
        >
          <InputLabel>
            {
              <span className="font-change-style">
                {<span>Select course type</span>}
              </span>
            }
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={courseStatusValue}
            label={
              <span className="font-change-style">
                {<span>Select course type</span>}
              </span>
            }
            MenuProps={MenuProps}
            onChange={handleChangeCourse}
          >
            {courseStatus.map((val, key) => {
              return (
                <MenuItem key={key} value={val}>
                  {<span className="font-change-style">{val}</span>}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          style={{
            width: "100%",
            alignSelf: "center",
            marginTop: "20px",
          }}
        >
          <InputLabel>
            {
              <span className="font-change-style">
                {<span>Select course</span>}
              </span>
            }
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={courseValue}
            label={
              <span className="font-change-style">
                {<span>Select course</span>}
              </span>
            }
            MenuProps={MenuProps}
            onChange={handleChangeCourseValue}
          >
            {courseReducer?.getAllPublishedCourses?.responseData?.map(
              (val, key) => {
                return (
                  <MenuItem key={key} value={val?.courseId}>
                    {<span className="font-change-style">{val?.title}</span>}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </FormControl>
        <TableContainer
          component={Paper}
          style={{
            marginTop: "20px",
          }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell align="right">
                  {<span>S.No.</span>}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {<span>Name</span>}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {<span>Email Id</span>}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {<span>Mobile No</span>}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {<span>OBG PG</span>}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {<span>OBG Resident</span>}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {<span>Interest</span>}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {enrolledUserList?.map((val, key) => {
                return <Row key={key} row={val} count={key + 1} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {apiState.showLoader && <BlueLoader />}
      </div>
    </>
  );
};

export default Enrollment;
