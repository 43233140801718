const DataStoreKey = {
    IS_LOGGED_IN: "ISLOGGEDIN",
    USER_FIRST_NAME: "USER_FIRST_NAME",
    BEARER_TOKEN: "BEARER_TOKEN",
    USER_ID: "USER_ID",
    USER_EMAIL: "USER_EMAIL",
    COURSE_NAME: "COURSE_NAME",
    SECTION_NAME: "SECTION_NAME",
    CHAPTER_NAME: "CHAPTER_NAME"
};

const setDataStoreItem = (key, value) => localStorage.setItem(key, value);

const getDataStoreItem = (key) => localStorage.getItem(key);


export { DataStoreKey, setDataStoreItem, getDataStoreItem };
