import "./App.css";
import { Dashboard } from "./dashboard/Dashboard";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/Store";
import { ProtectedRoute } from "./protected-route/ProtectedRoute";
import { useEffect, useState } from "react";
import {
  DataStoreKey,
  getDataStoreItem,
} from "./persistence/PersistenceStorage";
import { TestComponent } from "./test/TestComponent";
import { Home } from "./home/Home";
import CourseList from "./courseManager/course-list/CourseList";
import ProgramManager from "./courseManager/program-manager/ProgramManager";
import CreateCourse from "./courseManager/create-course/CreateCourse";
import "./firebaseconfig/FirebaseConfig";
import { Page404 } from "./404/Page404";
import socket from "./socket/SocketCon";
import Settings from "./settings/Settings";
import NotificationManager from "./notification/notification-manager/NotificationManager";
import AppManager from "./manage-app/AppManager/AppManager";
import Enrollment from "./enrollment/Enrollment";
import { LoginScreen } from "./login-screen/LoginScreen";
import Transactions from "./transactions/Transactions";
import QuickAddQuestion from "./quickaddquestion/QuickAddQuestion";
import HightLightsManager from "./highlights/highlights_manager/HighLightsManager";
import CoursesManager from "./courseManager/courses/courses_manager";

function App() {
  // const navigate = useNavigate();
  //const dispatch = useDispatch();
  const [login, setLogin] = useState(
    getDataStoreItem(DataStoreKey.IS_LOGGED_IN)
  );

  useEffect(() => {
    // socket.on("connect", () => {
    //   console.log("socket connected");
    // });
    //  socket.on("logout", () => {
    //    console.log("socket logout");
    //    alert("you have been logged out !")
    //    localStorage.clear();
    //   //  dispatch({
    //   //    type: "LOGOUT",
    //   //  });
    //    //navigate("/login");
    //  });
    setLogin(getDataStoreItem(DataStoreKey.IS_LOGGED_IN));
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            exact={true}
            path="/login"
            element={
              <ProtectedRoute authentication={login} render={<LoginScreen />} />
            }
          />
          <Route
            exact={true}
            path="/dashboard"
            element={
              <ProtectedRoute authentication={login} render={<Dashboard />} />
            }
          >
            <Route exact={true} path="/dashboard" element={<Home />} />
            <Route
              exact={true}
              path="/dashboard/createTest"
              element={<TestComponent />}
            />
            <Route
              exact={true}
              path="/dashboard/course"
              element={<CoursesManager />}
            />
            <Route
              exact={true}
              path="/dashboard/course/create"
              element={<CreateCourse />}
            />
            <Route
              exact={true}
              path="/dashboard/course/:id"
              element={<ProgramManager />}
            />
            <Route
              exact={true}
              path="/dashboard/manage-app"
              element={<AppManager />}
            />
            <Route
              exact={true}
              path="/dashboard/notification"
              element={<NotificationManager />}
            />
             <Route
              exact={true}
              path="/dashboard/highlights"
              element={<HightLightsManager />}
            />
            <Route
              exact={true}
              path="/dashboard/setting"
              element={<Settings />}
            />
            <Route
              exact={true}
              path="/dashboard/enrollments"
              element={<Enrollment />}
            />
            <Route
              exact={true}
              path="/dashboard/transactions"
              element={<Transactions />}
            />
          </Route>
          <Route
            exact={true}
            path="/quickaddquestion"
            element={
              <ProtectedRoute authentication={login} render={<QuickAddQuestion />} />
            }
          ></Route>
          <Route
            exact={true}
            path="/quickaddquestion"
            element={
              <ProtectedRoute authentication={login} render={<QuickAddQuestion />} />
            }
          ></Route>
          <Route
            exact={true}
            path="/"
            element={
              login ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route exact={true} path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
