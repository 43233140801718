import { MakeNewtworkCall } from "../../network/BaseAxios";
import {
  DataStoreKey,
  getDataStoreItem,
  setDataStoreItem,
} from "../../persistence/PersistenceStorage";
import store from "../Store";
import { ActionType } from "./actionType";
import { Constant } from "../../constants/Constant";
import { RequestType } from "../../network/RequestType";

//? make network call login

const makeNewtworkCallLogin =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status, statusText } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      if (status === 200 && data.responseStatus.statusCode === 200) {
        setDataStoreItem(DataStoreKey.IS_LOGGED_IN, true);
        setDataStoreItem(
          DataStoreKey.USER_FIRST_NAME,
          data.responseData.firstName
        );
        setDataStoreItem(
          DataStoreKey.BEARER_TOKEN,
          data.responseData.bearerToken
        );
        setDataStoreItem(DataStoreKey.USER_ID, data.responseData.userId);
        setDataStoreItem(DataStoreKey.USER_EMAIL, data.responseData.email);
        dispatch({
          type: ActionType.LOGIN,
          payload: data.responseData,
        });
      } else {
        dispatch({
          type: ActionType.ERROR_VISIBLE,
          payload: { errorVisible: true },
        });
        dispatch({
          type: ActionType.ERROR_MESSAGE,
          payload: { errorMessage: data.responseData.message },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.ERROR_VISIBLE,
        payload: { errorVisible: true },
      });
      dispatch({
        type: ActionType.ERROR_MESSAGE,
        payload: { errorMessage: error.response.data.responseData.message },
      });
    }
  };

const intialSideNavigationModel = (payload) => {
  return {
    type: ActionType.INITIAL_SIDE_NAVIGATION_VALUE,
    payload: payload,
  };
};

const updatedSideNavigationModel = (newKey) => {
  const tempNavItems = store.getState().sideNavigationReducer.navItems;
  tempNavItems.forEach((element, key) => {
    if (newKey === tempNavItems.length - 1) {
      tempNavItems[key].active = false;
      tempNavItems[0].active = true;
    } else {
      if (key === newKey) {
        element.active = true;
      } else {
        element.active = false;
      }
    }
  });

  return {
    type: ActionType.UPDATED_SIDE_NAVIGATION_VALUE,
    payload: tempNavItems,
  };
};

//? get all course network call

const makeNetworkCallGetAllCourse =
  (page, type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = `${endPoint}?size=50&page=${page}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.GET_ALL_COURSE,
          payload: data.responseData,
        });
      } else {
        console.log(data);
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
        },
      });
      console.log(error);
    }
  };

  //? get all short course network call

const makeNetworkCallGetAllShortCourse =
(page, type, endPoint, body, header) => async (dispatch) => {
  dispatch({
    type: ActionType.SHOW_LOADER,
    payload: { showLoader: true },
  });
  try {
    const { data, status } = await MakeNewtworkCall(
      type,
      endPoint,
      body,
      header
    );
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: false },
    });    
    if (status === 200) {
      dispatch({
        type: ActionType.GET_ALL_SHORT_COURSE,
        payload: data.responseData,
      });
    } else {
      console.log(data);
    }
  } catch (error) {
    
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: false },
    });
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: "Something went wrong !!",
      },
    });
    console.log(error);
  }
};

//? network call for create course

const makeNetworkCallCreateCourse =
  (type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: "Course created successfully",
          },
        });
        dispatch({
          type: ActionType.COURSE_CREATE_RESPONSE,
          payload: {
            createCourseResponse: {
              courseId: data.responseData,
              statusCode: 200,
            },
          },
        });
        console.log(data);
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: error?.response?.data?.responseData?.message ?? "Something went wrong!!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
      console.log(error);
    }
  };

//? network call for update course

const makeNetworkCallUpdateCourse =
  (type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: "Course updated successfully",
          },
        });
        dispatch({
          type: ActionType.UPDATE_COURSE_RESPONSE,
          payload: {
            updateCourse: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        console.log(data);
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      console.log(error);
    }
  };

//? create section network call

const makeNetworkCallCreateSection =
  (type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: "Section added successfully",
          },
        });
        dispatch({
          type: ActionType.SECTION_CREATE_RESPONSE,
          payload: {
            createSectionResponse: {
              response: data.responseData,
              statusCode: 200,
            },
          },
        });
        console.log(data);
      } else {
        console.log(data);
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      console.log(error);
    }
  };

//? get coursee by id network call

const makeNetworkCallGetCourseById =
  (courseId, type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = `${endPoint}?courseId=${courseId}&userId=${getDataStoreItem(
        DataStoreKey.USER_ID
      )}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.GET_COURSE_BY_ID,
          payload: {
            getCourseByIdResponse: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
        console.log(data);
      } else {
        console.log(data);
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      console.log(error);
    }
  };

//? delete coursee by id network call

const makeNetworkCallForDeleteCourseById =
  (courseId, type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = `${endPoint}/${courseId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.DELETE_COURSE,
          payload: {
            courseDeleteResponse: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
        console.log(data);
      } else {
        console.log(data);
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      console.log(error);
    }
  };

//? get section by course id api call

const makeNetworkCallForGetSectionByCourseId =
  (courseId, type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = `${endPoint}${courseId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.GET_SECTION_BY_COURSE_ID,
          payload: {
            getSectionByCourseId: {
              statusCode: 200,
              responseData: data.responseData,
            },
          },
        });
      } else {
        console.log(data);
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      console.log(error);
    }
  };

//? network call for create chapter by section id

const makeNetworkCallCreateChapterBySectionId =
  (type, endPoint, body, header, sectionId) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.CREATE_CHAPTER_BY_SECTION_ID,
          payload: {
            createChapterBySectionId: {
              responseData: data.responseData,
              statusCode: 200,
              sectionId: sectionId,
            },
          },
        });
        console.log(data);
      } else {
        console.log(data);
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      console.log(error);
    }
  };

//? network call for get chapter by section id

const makeNetworkCallForGetChapterBySectionId =
  (type, endPoint, body, header, sectionId) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = endPoint + `?sectionId=${sectionId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.GET_ALL_CHAPTER_BY_SECTION_ID,
          payload: {
            getAllChapterBySectionId: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
        console.log(data);
      } else {
        console.log(data);
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      console.log(error);
    }
  };

//? create question request

const createQuestionRequest = (questionRequest) => async (dispatch) => {
  dispatch({
    type: ActionType.CREATE_QUESTION_REQUEST,
    payload: {
      createQuestionRequest: {
        questionRequest: questionRequest,
      },
    },
  });
};

//? network call create question mapping

const makeNetworkCallForCreateQuestionMapping =
  (type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.CHAPTER_QUESTION_MAPPING,
          payload: {
            chapterQuestionMapping: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
        console.log(data);
      } else {
        console.log(data);
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      console.log(error);
    }
  };

//? network call upload files by chapter id

const makeNetworkCallUploadFilesByChapterId =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.UPLOAD_FILES_BY_CHAPTER_ID,
          payload: {
            uploadFilesByChapterId: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
      }
    } catch (error) {}
  };

//? network call get chapter by id

const makeNetworkCallForGetChapterById =
  (type, endPoint, chapterId, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = `${endPoint}${chapterId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.GET_CHAPTER_BY_ID,
          payload: {
            getChapterById: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
    }
  };

//? network call delete type by id

const makeNetworkCallDeleteTypeById =
  (type, endPoint, fileId, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = `${endPoint}${fileId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.DELETE_TYPE_BY_ID,
          payload: {
            deleteTypeById: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
        console.log(data);
      } else {
        console.log(data);
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      console.log(error);
    }
  };

//? make network call to send notification to all device type

const makeNetworkCallSendNotificationToAllDeviceType =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.SEND_NOTIFICATION_TO_ALL_DEVICE_TYPE,
          payload: {
            sendNotificationToAllDeviceType: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
    }
  };

//? make network call to create carousel

const makeNetworkCallCreateCarousel =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      if (status == 200) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: Constant.CAROUSEL_CREATED_SUCCESSFULLY,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
          },
        });
        dispatch({
          type: ActionType.CREATE_CAROUSEL,
          payload: {
            createCarousel: {
              responseMessage: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call to get all carousel

const makeNetworkCallGetAllCarousel =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );

      if (status == 200) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          payload: { showLoader: false },
        });
        dispatch({
          type: ActionType.GET_ALL_CAROUSEL,
          payload: {
            getAllCarousel: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call to delete carousel

const makeNetworkCallDeleteCarousel =
  (type, endPoint, carouselId, body, header) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      const updatedEndPoint = `${endPoint}${carouselId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );

      if (status == 200) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          payload: { showLoader: false },
        });
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
          },
        });
        dispatch({
          type: ActionType.DELETE_CAROUSEL,
          payload: {
            deleteCarousel: {
              responseMessage: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call to delete section

const makeNetworkCallDeleteSection =
  (type, endPoint, courseId, sectionId, body, header) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      const updatedEndPoint = `${endPoint}?courseId=${courseId}&sectionId=${sectionId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );

      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          payload: { showLoader: false },
        });
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
          },
        });
        dispatch({
          type: ActionType.DELETE_SECTION,
          payload: {
            deleteSection: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

// //? make network call to delete section mapping

// const makeNetworkCallDeleteSectionMapping =
//   (type, endPoint, sectionId, courseId, body, header) => async (dispatch) => {
//     try {
//       dispatch({
//         type: ActionType.SHOW_LOADER,
//         payload: { showLoader: false },
//       });
//       const updatedEndPoint = `${endPoint}sectionId=${sectionId}&courseId=${courseId}`;
//       const { data, status } = await MakeNewtworkCall(
//         type,
//         updatedEndPoint,
//         body,
//         header
//       );

//       if (status == 200) {
//         dispatch({
//           type: ActionType.SHOW_LOADER,
//           payload: { showLoader: false },
//         });
//         dispatch({
//           type: ActionType.SHOW_SNACKBAR,
//           payload: {
//             showSnackBar: true,
//             snackBarMessage: data.responseData,
//             snackBarSeverity: Constant.SEVERITY_SUCCESS,
//           },
//         });
//         dispatch({
//           type: ActionType.SECTION_DELETE_MAPPING,
//           payload: {
//             deleteSectionMapping: {
//               responseData: data.responseData,
//               statusCode: 200,
//             },
//           },
//         });
//       } else {
//         dispatch({
//           type: ActionType.SHOW_SNACKBAR,
//           payload: {
//             showSnackBar: true,
//             snackBarMessage: data.responseData.message,
//             snackBarSeverity: Constant.SEVERITY_ERROR,
//             apiError: true,
//           },
//         });
//       }
//     } catch (error) {
//       dispatch({
//         type: ActionType.SHOW_LOADER,
//         payload: { showLoader: false },
//       });
//       dispatch({
//         type: ActionType.SHOW_SNACKBAR,
//         payload: {
//           showSnackBar: true,
//           snackBarMessage: "Something went wrong !!",
//           snackBarSeverity: Constant.SEVERITY_ERROR,
//           apiError: true,
//         },
//       });
//     }
//   };

//? make network call to upload files by mapping

const makeNetworkCallMapDurationWithVideo =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: true },
      });
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          payload: { showLoader: false },
        });
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
            apiError: true,
          },
        });
        dispatch({
          type: ActionType.MAP_DURATION_WITH_VIDEOS,
          payload: {
            mapDurationVideos: {
              responseData: data,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      console.log("error", error.toString());
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call to delete question by id

const makeNetworkCallDeleteQuestionById =
  (type, endPoint, questionId, body, header) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      const updatedEndPoint = `${endPoint}?id=${questionId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );

      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          payload: { showLoader: false },
        });
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
          },
        });
        dispatch({
          type: ActionType.DELETE_QUESTION_BY_ID,
          payload: {
            deleteQuestionById: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call delete question by id from here
const makeNetworkCallDeleteQuestionByIdFromHere =
  (type, endPoint, questionId, chapterId, body, header) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      const updatedEndPoint = `${endPoint}?questionId=${questionId}&chapterId=${chapterId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );

      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          payload: { showLoader: false },
        });
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
          },
        });
        dispatch({
          type: ActionType.DELETE_QUESTION_BY_ID_FROM_HERE,
          payload: {
            deleteQuestionByIdFromHere: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call to update section

const makeNetworkCallUpdateSection =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: true },
      });
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );

      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          payload: { showLoader: false },
        });
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
          },
        });
        dispatch({
          type: ActionType.UPDATE_SECTION,
          payload: {
            updateSection: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call to delete chapter

const makeNetworkCallDeleteChapterById =
  (type, endPoint, sectionId, chapterId, body, header) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      const updateEndPoint = `${endPoint}?chapterId=${chapterId}&sectionId=${sectionId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updateEndPoint,
        body,
        header
      );

      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_LOADER,
          payload: { showLoader: false },
        });
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
          },
        });
        dispatch({
          type: ActionType.DELETE_CHAPTER_BY_ID,
          payload: {
            deleteChapterById: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call to publish course

const makeNetworkCallPublishCourse =
  (type, endPoint, courseId, isPublish, body, header) => async (dispatch) => {
    try {
      const updateEndPoint = `${endPoint}?courseId=${courseId}&publish=${isPublish}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updateEndPoint,
        body,
        header
      );

      if (status == 200) {
        dispatch({
          type: ActionType.PUBLISH_COURSE,
          payload: {
            publishCourse: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call to upload question image

const makeNetworkCallUploadQuestionImage =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );

      if (status == 200) {
        dispatch({
          type: ActionType.QUESTION_IMAGE,
          payload: {
            questionImage: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call to upload question image for edit question

const makeNetworkCallUploadQuestionImageForEditQuestion =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );

      if (status == 200) {
        dispatch({
          type: ActionType.EDIT_QUESTION_IMAGE,
          payload: {
            editQuestionImage: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call for edit question

const makeNetworkCallForEditQuestion =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );

      if (status == 200) {
        dispatch({
          type: ActionType.EDIT_QUESTION,
          payload: {
            editQuestion: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? get all course for files upload

const makeNetworkCallForGetAllCourse =
  (page, type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = `${endPoint}?size=100&page=${page}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.GET_ALL_COURSE_RESPONSE,
          payload: {
            getAllCourse: {
              responseData: data.responseData.courseResponse,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? get section by course id api call for copy files feature

const makeNetworkCallForGetSectionByCourseIdForCopyFiles =
  (courseId, type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = `${endPoint}${courseId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.GET_SECTION_BY_COURSE_ID_FOR_COPY_FILES,
          payload: {
            getSectionByCourseIdForCopyFiles: {
              statusCode: 200,
              responseData: data.responseData,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? network call for get chapter by section id for copy files feature

const makeNetworkCallForGetChapterBySectionIdForCopyFiles =
  (type, endPoint, body, header, sectionId) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = endPoint + `?sectionId=${sectionId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_FILES,
          payload: {
            getAllChapterBySectionIdForCopyFiles: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? network call for add file

const makeNetworkCallForAddFile =
  (type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
            apiError: false,
          },
        });
        dispatch({
          type: ActionType.ADD_FILE,
          payload: {
            addFile: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? network call for copy chapter upload

const makeNetworkCallForCopyChapter =
  (type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 201) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
            apiError: false,
          },
        });
        dispatch({
          type: ActionType.COPY_CHAPTER,
          payload: {
            copyChapter: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? network call for copy section upload

const makeNetworkCallForCopySection =
  (type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
            apiError: false,
          },
        });
        dispatch({
          type: ActionType.COPY_SECTION,
          payload: {
            copySection: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? network call for copy course upload

const makeNetworkCallForCopyCourse =
  (type, endPoint, destinationCourseId, courseId, body, header) =>
  async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedUrl = `${endPoint}?sourceCourseId=${destinationCourseId}&destinationCourseId=${courseId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedUrl,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
            apiError: false,
          },
        });
        dispatch({
          type: ActionType.COPY_COURSE,
          payload: {
            copyCourse: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? get all course for questions upload

const makeNetworkCallForGetAllCourseForQuestionsUpload =
  (page, type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = `${endPoint}?size=100&page=${page}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status == 200) {
        dispatch({
          type: ActionType.GET_ALL_COURSE_FOR_COPY_QUESTIONS_RESPONSE,
          payload: {
            getAllCourseForCopyQuestions: {
              responseData: data.responseData.courseResponse,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? get section by course id api call for copy questions feature

const makeNetworkCallForGetSectionByCourseIdForCopyQuestions =
  (courseId, type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = `${endPoint}${courseId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.GET_SECTION_BY_COURSE_ID_FOR_COPY_QUESTIONS,
          payload: {
            getSectionByCourseIdForCopyQuestions: {
              statusCode: 200,
              responseData: data.responseData,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? network call for get chapter by section id for copy questions feature

const makeNetworkCallForGetChapterBySectionIdForCopyQuestions =
  (type, endPoint, body, header, sectionId) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = endPoint + `?sectionId=${sectionId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.GET_ALL_CHAPTER_BY_SECTION_ID_FOR_COPY_QUESTIONS,
          payload: {
            getAllChapterBySectionIdForCopyQuestions: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? network call for add questions

const makeNetworkCallForAddQuestions =
  (type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
            apiError: false,
          },
        });
        dispatch({
          type: ActionType.ADD_QUESTIONS,
          payload: {
            addQuestions: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {        
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      console.log("data is ", error);
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: error?.response?.data?.responseData?.message ?? "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? network call for delete question image by id

const makeNetworkCallDeleteQuestionImageById =
  (type, endPoint, key, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedUrl = `${endPoint}?id=${key}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedUrl,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
            apiError: false,
          },
        });
        dispatch({
          type: ActionType.DELETE_QUESTION_IMAGE_BY_ID,
          payload: {
            deleteQuestionImageById: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? network call for delete question image by id

const makeNetworkCallGetAllPublishedCourses =
  (type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedUrl = `${endPoint}?size=100&page=0`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedUrl,
        body,
        header
      );
      // dispatch({
      //   type: ActionType.SHOW_LOADER,
      //   payload: { showLoader: false },
      // });
      if (status === 200) {
        dispatch({
          type: ActionType.GET_ALL_PUBLISHED_COURSES,
          payload: {
            getAllPublishedCourses: {
              responseData: data.responseData.courseResponse,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? network call for delete course duration price mapping

const makeNetworkCallForDeleteCourseDurationPriceMapping =
  (type, endPoint, courseDurationId, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedUrl = `${endPoint}?courseDurationId=${courseDurationId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedUrl,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData,
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
            apiError: false,
          },
        });
        dispatch({
          type: ActionType.DELETE_COURSE_DURATION_MAPPING,
          payload: {
            deleteCourseDurationMapping: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? network call for delete course duration price mapping

const makeNetworkCallForGetQuestionsByChapterId =
  (type, endPoint, chapterId, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedUrl = `${endPoint}?chapterId=${chapterId}`;
      const { data, status } = await MakeNewtworkCall(
        type,
        updatedUrl,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.GET_QUESTIONS_BY_CHAPTER_ID,
          payload: {
            getQuestionsByChapterId: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

// make network call get all question
const makeNetworkCallGetAllQuestion =
  (questionName, destinationChapterId, sourceChapterId, url, body, header) => async (dispatch) => {
    var updatedUrl = `${url}?size=10000&page=0&questionName=${questionName}`;
    if(destinationChapterId !== undefined && destinationChapterId !== ""){
      updatedUrl = `${updatedUrl}&destChapterId=${destinationChapterId}`;
    }
    if(sourceChapterId !== undefined && sourceChapterId !== ""){
      updatedUrl = `${updatedUrl}&chapterId=${sourceChapterId}`;
    }
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { status, data } = await MakeNewtworkCall(
        RequestType.GET,
        updatedUrl,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.GET_ALL_QUESTION,
          payload: {
            getAllQuestion: {
              statusCode: 200,
              responseData: data.responseData,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: error?.response?.data?.responseData?.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

// api call for map order with question

const makeNetworkCallMapOrderWithQuestion =
  (chapterId, url, body, header) => async (dispatch) => {
    const updatedUrl = `${url}?chapterId=${chapterId}`;
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { status, data } = await MakeNewtworkCall(
        RequestType.POST,
        updatedUrl,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.MAP_ORDER_WITH_QUESTION,
          payload: {
            mapOrderWithQuestion: {
              statusCode: 200,
              responseData: data.responseData,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: error?.response?.data?.responseData?.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

// make network call get question by id
const makeNetworkCallGetQuestionById =
  (questionId, url, body, header) => async (dispatch) => {
    const updatedUrl = `${url}?questionId=${questionId}`;

    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { status, data } = await MakeNewtworkCall(
        RequestType.GET,
        updatedUrl,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.GET_QUESTION_BY_ID,
          payload: {
            getQuestionById: {
              statusCode: 200,
              responseData: data.responseData,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: error?.response?.data?.responseData?.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

//? make network call get results by chapter id
const makeNetworkCallGetResultsByChapterId =
  (type, endPoint, body, header) => async (dispatch) => {
    try {
      const { data, status } = await MakeNewtworkCall(
        type,
        endPoint,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.GET_RESULT_BY_CHAPTER_ID,
          payload: {
            getResultsByChapterId: {
              responseMessage: "Results sent successfully!",
              statusCode: 200,
            },
          },
        });
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: "Results sent successfully!",
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data.responseData.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: "Something went wrong !!",
          snackBarSeverity: Constant.SEVERITY_ERROR,
        },
      });
    }
  };
export {
  makeNewtworkCallLogin,
  intialSideNavigationModel,
  updatedSideNavigationModel,
  makeNetworkCallGetAllCourse,
  makeNetworkCallCreateCourse,
  makeNetworkCallCreateSection,
  makeNetworkCallGetCourseById,
  makeNetworkCallForDeleteCourseById,
  makeNetworkCallForGetSectionByCourseId,
  makeNetworkCallCreateChapterBySectionId,
  makeNetworkCallForGetChapterBySectionId,
  createQuestionRequest,
  makeNetworkCallForCreateQuestionMapping,
  makeNetworkCallUploadFilesByChapterId,
  makeNetworkCallForGetChapterById,
  makeNetworkCallDeleteTypeById,
  makeNetworkCallSendNotificationToAllDeviceType,
  makeNetworkCallCreateCarousel,
  makeNetworkCallGetAllCarousel,
  makeNetworkCallDeleteCarousel,
  makeNetworkCallDeleteSection,
  makeNetworkCallMapDurationWithVideo,
  makeNetworkCallDeleteQuestionById,
  makeNetworkCallUpdateSection,
  makeNetworkCallDeleteChapterById,
  makeNetworkCallUpdateCourse,
  makeNetworkCallPublishCourse,
  makeNetworkCallUploadQuestionImage,
  makeNetworkCallUploadQuestionImageForEditQuestion,
  makeNetworkCallForEditQuestion,
  makeNetworkCallForGetAllCourse,
  makeNetworkCallForGetSectionByCourseIdForCopyFiles,
  makeNetworkCallForGetChapterBySectionIdForCopyFiles,
  makeNetworkCallForAddFile,
  makeNetworkCallForGetAllCourseForQuestionsUpload,
  makeNetworkCallForGetSectionByCourseIdForCopyQuestions,
  makeNetworkCallForGetChapterBySectionIdForCopyQuestions,
  makeNetworkCallForAddQuestions,
  makeNetworkCallDeleteQuestionImageById,
  makeNetworkCallGetAllPublishedCourses,
  makeNetworkCallForCopyChapter,
  makeNetworkCallForCopySection,
  makeNetworkCallForCopyCourse,
  makeNetworkCallForDeleteCourseDurationPriceMapping,
  makeNetworkCallForGetQuestionsByChapterId,
  makeNetworkCallDeleteQuestionByIdFromHere,
  makeNetworkCallGetAllQuestion,
  makeNetworkCallMapOrderWithQuestion,
  makeNetworkCallGetQuestionById,
  makeNetworkCallGetResultsByChapterId,
  makeNetworkCallGetAllShortCourse
};
