export const Constant = {
  FUTURA_CONDENSED: "FuturaCondensed",
  SEVERITY_SUCCESS: "success",
  SEVERITY_ERROR: "error",
  CAROUSEL_CREATED_SUCCESSFULLY: "Carousel created successfully",
  DAYS: "Days",
  MONTH: "Month",
  YEAR: "Year",
  ENTER_VALID_EMAIL_ID: "Please enter valid email id",
  NOTIFICATION_SENT_SUCCESSFULLY: "Notification sent successfully !!",
  EMAIL_SENT_SUCCESSFULLY: "Email sent successfully !!",
  COURSE_STATUS_ALL: "All",
  COURSE_STATUS_EXPIRED: "Expired",
  COURSE_STATUS_ACTIVE: "Active",
  PAYMENT_CAPTURED: "Captured",
  PAYMENT_AUTHORIZED: "Authorized",
  PLEASE_SELECT_COURSE: "Please select course !!",


  //Side navigation model constant
  HOME: "Home",
  TEST: "Test",
  COURSE: "Course",
  ENROLLMENTS: "Enrollments",
  TRANSACTIONS: "Transactions",
  MANAGE_APP: "Manage App",
  NOTIFICATION: "Notification",
  HIGHLIGHTS: "Highlights",
  SETTINGS: "Settings",
  LOG_OUT: "Logout",


  ADMIN_EMAIL_ID: "drmonalisingh3@gmail.com"


};