import Tab from "@mui/material/Tab";
import { useState } from "react";
import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import CourseList from "../course-list/CourseList";
import ShortCourseList from "../course-list/ShortCourseList";

const CoursesManager = () => {
  const [value, setValue] = useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext
      value={value}
      textColor="secondary"
      indicatorColor="secondary"
      aria-label="secondary tabs example"
    >
      <TabList onChange={handleChange} variant="fullWidth" className="tab-list">
        <Tab
          value="one"
          label={<span className="font-change-style">Course</span>}
          className="tab-style"
        />
        <Tab
          value="two"
          label={<span className="font-change-style">Short Course</span>}
          className="tab-style"
        />
      </TabList>
      <TabPanel value="one">
        <CourseList />
      </TabPanel>
      <TabPanel value="two">
        <ShortCourseList />
      </TabPanel>
    </TabContext>
  );
};

export default CoursesManager;
