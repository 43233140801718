import { Constant } from "../../constants/Constant";

export let SideNavigationModel = [
  {
    title: Constant.HOME,
    icon: <i class="fa-solid fa-house fa-bounce"></i>,
    link: "/dashboard",
    active: true,
    isAdmin: true,
  },
  {
    title: Constant.TEST,
    icon: <i class="fa-solid fa-file-pen fa-bounce"></i>,
    link: "/dashboard/createTest",
    active: false,
    isAdmin: true,
  },
  {
    title: Constant.COURSE,
    icon: <i class="fa-solid fa-book fa-bounce"></i>,
    link: "/dashboard/course",
    active: false,
    isAdmin: true,
  },
  {
    title: Constant.ENROLLMENTS,
    icon: <i class="fa-solid fa-graduation-cap fa-bounce"></i>,
    link: "/dashboard/enrollments",
    active: false,
    isAdmin: true,
  },
  {
    title: Constant.TRANSACTIONS,
    icon: <i class="fa-solid fa-money-check-dollar fa-bounce"></i>,
    link: "/dashboard/transactions",
    active: false,
    isAdmin: true,
  },
  {
    title: Constant.MANAGE_APP,
    icon: <i class="fa-solid fa-mobile-retro fa-bounce"></i>,
    link: "/dashboard/manage-app",
    active: false,
    isAdmin: true,
  },
  {
    title: Constant.NOTIFICATION,
    icon: <i class="fa-solid fa-bell fa-bounce"></i>,
    link: "/dashboard/notification",
    active: false,
    isAdmin: true,
  },
  {
    title: Constant.HIGHLIGHTS,
    icon: <i class="fa-solid fa-bell fa-bounce"></i>,
    link: "/dashboard/highlights",
    active: false,
    isAdmin: true,
  },
  {
    title: Constant.SETTINGS,
    icon: <i class="fa-solid fa-gear fa-bounce"></i>,
    link: "/dashboard/setting",
    active: false,
    isAdmin: true,
  },
  {
    title: Constant.LOG_OUT,
    icon: <i class="fa-solid fa-right-from-bracket fa-bounce"></i>,
    link: "/dashboard/logout",
    active: false,
    isAdmin: true,
  },
];
